import router from '@/routes/protectedRoute';

export default {
  axiosAddBeginBegin(state) {
    state.loading = true;
  },

  axiosAddBeginSuccess(state, data) {
    state.loading = false;
    state.data = data;
    router.back();
  },

  axiosAddBeginConfirm(state, data) {
    state.loading = false;
    state.confirmMessage = data.message;
    state.confirmId = data.id;
  },

  axiosAddBeginSuccessRedirect(state, data) {
    state.loading = false;
    state.data = data.data;
    router.push(data.redirect);
  },

  setConfirmMessage(state, message) {
    state.confirmMessage = message;
  },

  axiosAddBeginSuccessNoRedirect(state, data) {
    state.loading = false;
    state.data = data;
  },

  axiosAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosReadBegin(state) {
    state.loading = true;
  },

  axiosReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  axiosReadDetailSuccess(state, data) {
    state.loading = false;
    state.detail = data;
  },

  axiosReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosUpdateBegin(state) {
    state.loading = true;
  },

  axiosUpdateSuccess(state) {
    state.loading = false;
    // state.data = data;
    router.back();
  },

  axiosUpdateSuccessRedirect(state, url) {
    state.loading = false;
    // state.data = data;
    router.push(url);
  },

  axiosUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosUploadBegin(state) {
    state.loading = true;
  },

  axiosUploadSuccess(state) {
    state.loading = false;
    // state.data = data;
    router.back();
  },

  axiosUploadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosDeleteBegin(state) {
    state.loading = true;
  },

  axiosDeleteSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  axiosDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosResetPasswordBegin(state) {
    state.loading = true;
  },

  axiosResetPasswordSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  axiosResetPasswordErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosUnlockUserBegin(state) {
    state.loading = true;
  },

  axiosUnlockUserSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  axiosUnlockUserErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosApproveBegin(state) {
    state.loading = true;
  },

  axiosApproveSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  axiosApproveErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosSingleDataBegin(state) {
    state.loading = true;
  },

  axiosSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  axiosSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  // axiosUploadBegin(state) {
  //   state.fileLoading = true;
  // },

  // axiosUploadSuccess(state, data) {
  //   state.fileLoading = false;
  //   state.url = data;
  //   state.error = false;
  // },

  // axiosUploadErr(state, err) {
  //   state.fileLoading = false;
  //   state.error = err;
  // },

  axiosSearchBegin(state) {
    state.loading = true;
  },

  axiosSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  axiosSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosDownloadBegin(state) {
    state.loading = true;
  },

  axiosDownloadSuccess(state) {
    state.loading = false;
    // state.data = data;
    router.back();
  },

  axiosDownloadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosDownloadTemplateBegin(state) {
    state.loading = true;
  },

  axiosDownloadTemplateSuccess(state) {
    state.loading = false;
  },

  axiosDownloadTemplateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosSearchNikBegin(state) {
    state.loading = true;
  },

  axiosSearchNikSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  axiosSearchNikErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosAgreeBegin(state) {
    state.loading = true;
  },

  axiosAgreeSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  axiosAgreeErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosCheckPrivacyBegin(state) {
    state.loading = true;
  },

  axiosCheckPrivacySuccess(state) {
    state.loading = false;
  },

  axiosCheckPrivacyErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};
