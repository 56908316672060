<template>
  <RecordViewWrapper>
    <sdPageHeader title="Pegawai">
      <template #subTitle>
        <sdButton class="btn-add_new" size="default" type="primary" v-if="checkPermissions('create pegawai')">
          <router-link to="/master/pegawai-add"> <sdFeatherIcons type="plus" size="14" /> Tambah</router-link>
        </sdButton>
        <sdButton
          class="btn-download_template"
          size="default"
          type="secondary"
          @click="handleDownloadTemplate($event)"
          v-if="checkPermissions('create pegawai')"
        >
          <sdFeatherIcons class="mr-2" type="download" size="14" /> Download Template
        </sdButton>
        <sdButton class="btn-import" size="default" type="primary" v-if="checkPermissions('create pegawai')">
          <router-link to="/master/pegawai-import"> <sdFeatherIcons type="download" size="14" /> Import</router-link>
        </sdButton>
      </template>

      <template #buttons>
        <div class="search-container">
          <a-select
            v-model:value="formState.searchType"
            class="search-select"
            placeholder="Search by"
            :default-value="formState.searchType"
            @change="handleSearchTypeChange"
          >
            <a-select-option value="nama">Search Nama</a-select-option>
            <a-select-option value="nik">Search NIK</a-select-option>
            <a-select-option value="npwp">Search NPWP</a-select-option>
            <a-select-option value="email">Search Email</a-select-option>
          </a-select>
          <div class="search-box">
            <span class="search-icon">
              <sdFeatherIcons type="search" size="14" />
            </span>
            <a-input
              @input="handleInputChange"
              v-model:value.trim="formState.searchItem"
              type="text"
              name="recored-search"
              :maxlength="formState.searchType === 'nik' ? 16 : undefined"
              placeholder="Search Here"
              class="search-input"
            />
          </div>
        </div>
      </template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true }"
                  :dataSource="dataSource"
                  :columns="columns"
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { getItem } from '@/utility/localStorageControl';

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const checkPermissions = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    const columns = [
      {
        title: 'Nama',
        dataIndex: 'nama',
        key: 'nama',
      },
      {
        title: 'NIP',
        dataIndex: 'nip',
        key: 'nip',
      },
      {
        title: 'NIK',
        dataIndex: 'nik',
        key: 'nik',
      },
      {
        title: 'NPWP',
        dataIndex: 'npwp',
        key: 'npwp',
      },
      {
        title: 'Divisi',
        dataIndex: 'divisi_id',
        key: 'divisi_id',
      },
      /* {
        title: 'Bidang/Sub-Bidang/Bagian',
        dataIndex: 'bidang_id',
        key: 'bidang_id',
      }, */
      {
        title: 'Jabatan',
        dataIndex: 'jabatan',
        key: 'jabatan',
      },
      {
        title: 'No. HP / WA',
        dataIndex: 'no_hp',
        key: 'no_hp',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
      },
      ...(checkPermissions('update pegawai') || checkPermissions('delete pegawai')
        ? [
            {
              title: 'Actions',
              dataIndex: 'action',
              key: 'action',
              width: '90px',
            },
          ]
        : []),
    ];

    onMounted(() => {
      dispatch('axiosCrudGetData', 'pegawai');
    });

    const handleDelete = (id, name) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus ${name}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'pegawai',
            getData: () => {
              dispatch('axiosCrudGetData', 'pegawai');
            },
          });
        },
      });
    };

    const handleUnlock = (id, name) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin membuka kunci pegawai ${name}?`,
        okText: 'Unlock',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataUnlockPegawai', {
            id,
            getData: () => {
              dispatch('axiosCrudGetData', 'pegawai');
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
      searchType: 'nama',
    });

    const dataSource = computed(() =>
      crud.value.length
        ? crud.value.map((data, key) => {
            const {
              id,
              nama,
              no_hp,
              email,
              is_active,
              nik,
              nip,
              npwp,
              // bidang_id,
              // bidang,
              divisi_id,
              divisi,
              jabatan,
            } = data;

            return {
              key: key + 1,
              nama,
              // divisi: bidang && bidang.divisi ? bidang.divisi.name : '-',
              divisi_id: divisi_id ? divisi.name : '-',
              // bidang_id: bidang_id ? bidang.name : '-',
              jabatan: jabatan ? jabatan : '-',
              no_hp: no_hp ? no_hp : '-',
              email: email ? email : '-',
              nik,
              npwp,
              nip,
              // role: roles && roles.length ? roles[0].name : '',
              is_active: is_active ? 'Aktif' : 'Non Aktif',
              action: (
                <div class="table-actions">
                  {checkPermissions('update pegawai') && (
                    <router-link class="delete" to={`/master/pegawai-edit/${id}`}>
                      <sdFeatherIcons type="edit" size={14} title="Ubah" />
                    </router-link>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  {checkPermissions('delete pegawai') && (
                    <router-link class="delete" onClick={() => handleDelete(id, data.nama)} to="#">
                      <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
                    </router-link>
                  )}
                </div>
              ),
            };
          })
        : [],
    );

    const handleSearch = () => {
      dispatch('axiosDataSearch2', { url: 'pegawai', filter: formState.searchItem, type: formState.searchType });
    };

    const handleInputChange = () => {
      if (formState.searchType === 'nik') {
        if (formState.searchItem.length === 16 || formState.searchItem.length === 0) {
          handleSearch();
        }
      } else if (formState.searchType === 'npwp') {
        if (
          formState.searchItem.length === 16 ||
          formState.searchItem.length === 0 ||
          formState.searchItem.length === 15
        ) {
          handleSearch();
        }
      } else {
        handleSearch();
      }
    };

    const handleSearchTypeChange = () => {
      formState.searchItem = '';
    };

    const handleDownloadTemplate = event => {
      event.preventDefault();

      dispatch('axiosDownloadTemplate', {
        url: 'download-template-pegawai',
        fileName: 'Template Import Pegawai KEEPInsight.xlsx',
      });
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleUnlock,
      handleSearch,
      handleInputChange,
      handleSearchTypeChange,
      handleDownloadTemplate,
      checkPermissions,
    };
  },
};

export default ViewPage;
</script>

<style scoped>
.mr-2 {
  margin-right: 0.5rem;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.search-select {
  width: 180px;
}

.search-box {
  display: flex;
  align-items: center;
  position: relative;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.search-input {
  padding-left: 35px;
}
</style>
