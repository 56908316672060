import { getItem } from '@/utility/localStorageControl';

export default [
  // redirect from / to /dash
  {
    path: '/',
    redirect: () => {
      const permissions = getItem('permissions');

      if (permissions.includes('view kpi korporat')) {
        return '/dash/kpi-korporat';
      } else if (permissions.includes('search nik npwp')) {
        return '/anti-coi';
      } else if (permissions.includes('view pegawai')) {
        return '/master/pegawai';
      } else if (permissions.includes('view vendor')) {
        return '/master/vendor';
      }

      return '/dash/kpi-korporat'; // default redirect
    },
  },
  {
    path: '/dash',
    name: 'dash',
    component: () => import(/* webpackChunkName: "dash" */ '@/view/dash/index.vue'),
    children: [
      {
        path: 'kpi-korporat',
        name: 'dash-kpi-korporat',
        component: () => import(/* webpackChunkName: "kpi-korporat" */ '@/view/dash/kpi-korporat.vue'),
      },
      {
        path: 'kpi-divisi/:id',
        name: 'dash-kpi-divisi',
        component: () => import(/* webpackChunkName: "kpi-divisi" */ '@/view/dash/kpi-divisi.vue'),
      },
    ],
  },
];
