import router from '@/routes/protectedRoute';
import { getItem } from '@/utility/localStorageControl';

export default {
  loginBegin(state) {
    state.loading = true;
  },

  loginSuccess(state, data) {
    state.loading = false;
    state.login = data;

    const permissions = getItem('permissions');

    if (permissions.includes('view kpi korporat')) {
      router.push('/dash/kpi-korporat');
    } else if (permissions.includes('search nik npwp')) {
      router.push('/anti-coi');
    } else if (permissions.includes('view pegawai')) {
      router.push('/master/pegawai');
    } else if (permissions.includes('view vendor')) {
      router.push('/master/vendor');
    } else {
      router.push('/dash/kpi-korporat');
    }
  },

  loginSuccessFirst(state, data) {
    state.loading = false;
    state.data = data;
    router.push('/auth/first-login');
  },

  loginSuccessExpiredPassword(state, data) {
    state.loading = false;
    state.data = data;
    router.push('/auth/update-password');
  },

  resetPasswordSuccess(state, data) {
    state.loading = false;
    state.data = data;
    router.push('/auth/login');
  },

  loginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state, data) {
    state.loading = false;
    state.login = data;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  showPrivacyPopup(state, status) {
    state.showPrivacyPopup = status;
  },

  setShowPrivacyPopup(state, status) {
    state.showPrivacyPopup = status;
  },

  checkPrivacyPolicySuccess(state) {
    state.agreePrivacy = true;
  },

  checkPrivacyPolicyErr(state, err) {
    state.error = err;
  },
};
